const data = [


    {
        title: "Animation",
        content: "Get custom animations for your specific courses. Boost your sales today."
    },
    {
             title: "Animation",
        content: "Get custom animations for your specific courses. Boost your sales today."
    },
    {
        title: "Animation",
        content: "Get custom animations for your specific courses. Boost your sales today."
    }
]


export default data;