import React from "react";
import Welcome from "./Welcome";

const Homepage = () => {
  return (
    <>
      <Welcome></Welcome>
    </>
  );
};

export default Homepage;
